.label {
  width: 100%;
  max-width: max-content;
  display: inline-flex;
  align-items: center;
  gap: 12px;

  &__icon {
    min-width: 32px;
    min-height: 32px;
    max-width: max-content;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 6px;
  }

  &__name {
    flex: 1;
  }

  &.bright-green {
    & .label__icon {
      background-color: $color-background-pale-green;
    }

    & .label__name {
      color: $color-general-bright-green;
    }
  }

  &.dark-grey {
    & .label__icon {
      background-color: $color-background-dark-grey;
    }

    & .label__name {
      color: $color-general-dark-grey;
    }
  }
}
