.workspace-list {
  height: 100%;
  display: flex;
  align-items: flex-start;

  &__spin {
    height: 100%;

    & .ant-spin-container {
      height: 100%;
      display: flex;
      flex-direction: column;
    }
  }

  &__content {
    position: relative;
    padding: 40px 40px 0;
    height: 100%;
    flex: 1;
    display: flex;
    flex-direction: column;

    &-container {
      padding: 0 40px;
      flex: 1;
      display: flex;
      flex-direction: column;
    }
  }

  &__search {
    margin-bottom: 40px;
    display: flex;
    align-items: center;
    gap: 12px;
  }

  &__title {
    margin-top: 12px;
    margin-bottom: 52px;
  }
}

.workspace-list-group {
  margin-bottom: 72px;
  display: flex;
  flex-direction: column;
  gap: 6px;

  &__name-container {
    position: sticky;
    top: 0;
    padding-bottom: 6px;
    background-color: $color-general-white;
    z-index: 1;

    &::before {
      content: '';
      position: absolute;
      bottom: 5px;
      width: 100%;
      height: 1px;
      background-color: $color-outline-grey;
    }
  }

  &__name {
    position: relative;
    margin-bottom: 10px;
    display: inline-block;

    &::before {
      content: '';
      position: absolute;
      left: 0;
      bottom: -11px;
      width: 100%;
      height: 2px;
      background-color: $color-background-black;
    }
  }

  &__item {
    padding: 24px 0;
    display: flex;
    flex-direction: column;
    gap: 12px;
    border-bottom: 1px solid $color-outline-grey;
    cursor: pointer;

    &-container {
      display: flex;
      align-items: center;
      gap: 12px;
    }

    &-icon {
      width: 32px;
      height: 32px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: $color-background-pale-green;
      border-radius: 6px;
    }

    &-user {
      display: flex;
      align-items: center;
      gap: 8px;
    }

    &:hover {
      box-shadow: 0px 0px 12px rgba(41, 62, 53, 0.12);
      clip-path: inset(-12px 0px -12px 0px);
    }
  }
}

.workspace-list-subdivisions {
  max-width: 580px;
  height: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  border-left: 1px solid $color-outline-grey;

  &__header {
    margin-bottom: 12px;
    padding: 40px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 12px;
  }

  &__body {
    padding: 12px 80px 0;
    min-height: 0;
    flex: 1;
    display: flex;
    flex-direction: column;
  }

  &__item {
    margin-bottom: 8px;
    padding: 12px;
    border-radius: 8px;
    cursor: pointer;

    &-container {
      display: flex;
      align-items: center;
      gap: 24px;
    }

    &-label {
      font-family: $ff-Onest-Medium;
      font-size: $fs-m;
      text-decoration: none;
    }

    &.active {
      background-color: $color-hover-light-grey;

      & .workspace-list-subdivisions__item-label {
        font-family: $ff-Onest-SemiBold !important;
        font-size: $fs-m !important;
        text-decoration: none !important;
      }
    }

    &:hover {
      background-color: $color-hover-light-grey;
    }

    &:active {
      background-color: $color-pressed-light-grey;

      & .workspace-list-subdivisions__item-label {
        font-family: $ff-Onest-SemiBold !important;
        font-size: $fs-m !important;
        text-decoration: none !important;
      }
    }
  }
}

.workspace {
  height: 100%;
  display: flex;
  align-items: flex-start;

  &__spin {
    height: 100%;

    & .ant-spin-container {
      height: 100%;
      display: flex;
      flex-direction: column;
    }
  }

  &__content {
    position: relative;
    padding: 40px 40px 0;
    height: 100%;
    flex: 1;
    display: flex;
    flex-direction: column;

    &-container {
      position: relative;
      padding: 0 40px;
      flex: 1;
      display: flex;
      flex-direction: column;

      &-toggle {
        position: absolute;
        bottom: 8px;
        right: 40px;
        display: flex;
        align-items: center;
        gap: 8px;
      }
    }
  }

  &__search {
    margin-bottom: 40px;
    display: flex;
    align-items: center;
    gap: 12px;
  }

  &__title {
    margin-top: 12px;
    margin-bottom: 20px;
  }

  &__subtitle {
    margin-bottom: 52px;
    display: flex;
    align-items: center;
    gap: 32px;

    &-no-user {
      display: flex;
      align-items: center;
      gap: 12px;

      &-icon {
        width: 32px;
        height: 32px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: $color-hover-light-grey;
        border-radius: 6px;
      }
    }
  }
}

.workspace-group {
  margin-bottom: 72px;
  display: flex;
  flex-direction: column;
  gap: 6px;

  &__name-container {
    position: sticky;
    top: 0;
    padding-bottom: 6px;
    background-color: $color-general-white;
    z-index: 1;

    &::before {
      content: '';
      position: absolute;
      bottom: 5px;
      width: 100%;
      height: 1px;
      background-color: $color-outline-grey;
    }
  }

  &__name {
    position: relative;
    margin-bottom: 10px;
    display: inline-block;

    &::before {
      content: '';
      position: absolute;
      left: 0;
      bottom: -11px;
      width: 100%;
      height: 2px;
      background-color: $color-background-black;
    }
  }
}

.workspace-tools {
  max-width: 580px;
  height: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  border-left: 1px solid $color-outline-grey;

  &__header {
    margin-bottom: 12px;
    padding: 40px;
    height: 124px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 12px;
  }

  &__body {
    padding: 12px 80px 0;
    min-height: 0;
    flex: 1;
    display: flex;
    flex-direction: column;
  }

  &__item {
    margin-bottom: 8px;
    padding: 12px;
    border-radius: 8px;
    cursor: pointer;

    &-container {
      display: flex;
      align-items: center;
      gap: 24px;
    }

    &-label {
      font-family: $ff-Onest-Medium;
      font-size: $fs-m;
      text-decoration: none;
    }

    &.active {
      background-color: $color-hover-light-grey;

      & .workspace-list-subdivisions__item-label {
        font-family: $ff-Onest-SemiBold !important;
        font-size: $fs-m !important;
        text-decoration: none !important;
      }
    }

    &:hover {
      background-color: $color-hover-light-grey;
    }

    &:active {
      background-color: $color-pressed-light-grey;

      & .workspace-list-subdivisions__item-label {
        font-family: $ff-Onest-SemiBold !important;
        font-size: $fs-m !important;
        text-decoration: none !important;
      }
    }
  }
}

.workspace-position {
  height: 100%;
  display: flex;
  align-items: flex-start;

  &__spin {
    height: 100%;

    & .ant-spin-container {
      height: 100%;
      display: flex;
      flex-direction: column;
    }
  }

  &__properties {
    display: grid;
    align-items: flex-end;
    grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
    column-gap: 12px;
  }
}

.workspace-position-templates {
  max-width: 580px;
  height: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  border-right: 1px solid $color-outline-grey;

  &__header {
    padding: 40px;
    display: flex;
    align-items: center;
  }

  &__body {
    padding: 12px 80px 0;
    min-height: 0;
    flex: 1;
  }
}

.workspace-position-params {
  height: 100%;
  flex: 1;

  &__body {
    padding: 136px 80px 0;
    flex: 1;
  }

  &__footer {
    padding: 0 80px;
    display: flex;
    align-items: center;
    gap: 12px;
  }
}

.workspace-position-card-min {
  padding: 24px 0;
  display: flex;
  flex-direction: column;
  gap: 12px;
  border-bottom: 1px solid $color-outline-grey;
  cursor: pointer;

  &__header {
    display: flex;
    align-items: center;
    gap: 16px;
  }

  &:hover {
    box-shadow: 0px 0px 12px rgba(41, 62, 53, 0.12);
    clip-path: inset(-12px 0px -12px 0px);
  }
}

.workspace-position-card {
  &__block {
    display: flex;
    flex-direction: column;
    gap: 24px;

    &-header {
      padding-bottom: 12px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid $color-outline-grey;
    }

    &-list {
      display: grid;
      grid-template-columns: 1fr 1fr;
      row-gap: 20px;

      &-row {
        display: contents;
      }
    }
  }

  &__goods-list {
    display: grid;
    grid-template-columns: 1fr 1fr;

    &-item {
      padding: 32px 24px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      border: 1px solid $color-outline-light-grey;

      &-codes {
        display: flex;
        align-items: center;
        gap: 20px;
      }
    }
  }
}

.workspace-position-inventory-limit {
  display: flex;
  align-items: center;
  gap: 8px;
}
